.feature {
  display: flex;
  justify-content: center;
  padding: 1rem;
}

.featureContainer {
  min-width: 200px;
  height: auto;
  display: flex;
  justify-content: space-between;
  font-family: var(--font-family);

  padding: 2rem;
  gap: 2rem;
}

.featureHeader {
  max-width: 500px;
}

.featureHeader h2 {
  text-transform: capitalize;
  font-weight: bolder;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.featureHeader p {
  font-size: 1rem;
  text-transform: capitalize;
}
.gradient-text {
  background: linear-gradient(89.97deg, #ae67fa 1.84%, #f49867 102.67%);
  -webkit-background-clip: text; /* For Safari */
  background-clip: text;
  color: transparent;
}

.featureBody {
  flex: 1;
  display: grid;
  gap: 3rem;
  padding: 1rem;
}

.featureItem {
  display: flex;
  gap: 2rem;
}

.flex {
  position: relative;
}

.flex .styleLine {
  width: 40px;
  height: 5px;
  background-color: var(--color-subtext);
  position: absolute;
  top: 0;
}

.flex h2 {
  transform: translateY(0.5rem);
  color: rgba(255, 255, 255, 0.753);
  font-size: 1.1rem;
  display: grid;
  place-content: center;
}

.featureItem p {
  color: var(--color-blog);
  font-size: 1rem;
  transform: translateY(0.5rem);
}

@media screen and (max-width: 1300px) {
  .featureContainer {
    flex-wrap: wrap;
  }

  .featureHeader {
    max-width: 100%;
  }

  .featureBody {
    max-width: 100%;
  }
}
