.aboutContainer {
  /* global 92%+ browsers support */
  display: flex;
  justify-content: center;
  padding: 1rem;
}

.aboutgpt3 {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background: radial-gradient(
    circle at 0% 0%,
    rgba(27, 0, 255, 1) 0%,
    rgba(11, 4, 113, 1) 0%,
    rgba(6, 10, 163, 1) 50%,
    rgba(11, 38, 197, 1) 100%
  );
  padding: 2rem;
  width: 100%;
  height: auto;
  font-family: var(--font-family);
}

.aboutgpt3 h3 {
  color: white;
  font-weight: bolder;
  font-size: 1.3rem;
  transform: translateY(0.5rem);
}

.aboutheader,
.aboutfooterdiv {
  position: relative;
}

.aboutgpt3 .styleLine {
  width: 40px;
  height: 5px;
  background-color: var(--color-subtext);
  position: absolute;
  top: 0;
}

.aboutheader {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.aboutheader h3 {
  margin-bottom: 1rem;
}

.aboutheader p {
  max-width: 530px;
  color: rgba(255, 255, 255, 0.74);
  font-size: 1rem;
  line-height: 2rem;
  transform: translateY(-0.5rem);
}

.aboutbody {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  flex-wrap: wrap;
}

.aboutbody h2 {
  font-size: 2rem;
  max-width: 550px;
  font-weight: bolder;
}

.aboutbody p {
  font-size: 1.3rem;
  display: grid;
  place-items: center;
}

.gradient-text {
  background: linear-gradient(89.97deg, #ae67fa 1.84%, #f49867 102.67%);
  -webkit-background-clip: text; /* For Safari */
  background-clip: text;
  color: transparent;
}

.aboutfooter {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.aboutfooterdiv {
  width: 200px;
  height: 300px;
  display: grid;
}

.aboutfooterdiv p {
  color: rgba(255, 255, 255, 0.774);
}
