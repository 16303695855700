.footer {
  padding: 1rem;
}

.footerContainer {
  padding: 2rem;
  font-family: var(--font-family);
}

.footerContainer h1 {
  font-size: 3.6rem;
  text-align: center;
  margin-bottom: 1rem;
}

.button {
  text-align: center;
}

.footerContainer button {
  padding: 1rem 3rem;
  font-family: var(--font-family);
  border-radius: 10px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  margin-bottom: 10rem;
}

.footerContainer button:hover {
  background-color: black; /* Change background color on hover */
  color: white; /* Change text color on hover */
}

.footerFlex {
  color: rgb(255, 255, 255);
  display: flex;
  justify-content: space-around;
  gap: 1rem;
  margin-bottom: 2rem;
}
.body-1 {
  line-height: 2rem;
  transform: translateY(1.4rem);
}

.footerLinks,
.footerCompanyLinks,
.footerTouchLinks {
  display: grid;
  line-height: 4rem;
  color: rgba(255, 255, 255, 0.753);
}

.last {
  text-align: center;
  color: white;
}

.footerFlex p:hover {
  -webkit-animation: color-change-2x 2s linear infinite alternate both;
  animation: color-change-2x 2s linear infinite alternate both;
  cursor: pointer;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-2-3 22:30:5
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation color-change-2x
 * ----------------------------------------
 */
@-webkit-keyframes color-change-2x {
  0% {
    color: #19dcea;
  }
  100% {
    color: #b22cff;
  }
}
@keyframes color-change-2x {
  0% {
    color: #19dcea;
  }
  100% {
    color: #b22cff;
  }
}

@media screen and (max-width: 768px) {
  .footerFlex {
    display: block;
  }

  .body-1 p {
    margin-bottom: 2rem;
  }

  .footerLinks,
  .footerCompanyLinks,
  .footerTouchLinks {
    line-height: 2.7rem;
    margin-bottom: 1.3rem;
  }
}
