.possibilityContainer {
  display: flex;
  justify-content: flex-start;
  gap: 2rem;
  padding: 3rem;
  font-family: var(--font-family);
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.possibilityImg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1.5;
}

.possibilityImg img {
  width: 100%;
  height: 100%;
}

.possibilitybody {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.gradient-text {
  background: linear-gradient(89.97deg, #ae67fa 1.84%, #f49867 102.67%);
  -webkit-background-clip: text; /* For Safari */
  background-clip: text;
  color: transparent;
}

.styleColor {
  color: var(--color-subtext);
  text-transform: capitalize;
  font-size: 1rem;
}

.descr {
  color: var(--color-blog);
  font-size: 1rem;
}

.possibilitybody {
  line-height: 3rem;
}

.possibilitybody h2 {
  font-size: 2.3rem;
  text-transform: capitalize;
}
@media screen and (max-width: 830px) {
  .possibilityContainer {
    flex-direction: column;
  }

  .possibilityImg {
    margin: 1rem 0;
  }
}
