* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background-color: var(--color-bg);
}
a {
  color: unset;
  text-decoration: none;
}
a:hover {
  box-shadow: 0px 3px 3px blue;
}

.gradient_bg {
  /* global 92%+ browsers support */
  background: radial-gradient(
    circle at 3% 25%,
    rgba(0, 40, 83, 1) 0%,
    rgba(4, 6, 24, 1) 100%
  );
}

.gradient_text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.section_padding {
  padding: 4rem 6rem;
}

.section_margin {
  margin: 4rem 6rem;
}
.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@media screen and (max-width: 700px) {
  .section_padding {
    padding: 4rem;
  }

  .section_margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section_padding {
    padding: 4rem 2rem;
  }

  .section_margin {
    margin: 4rem 2rem;
  }
}
