.brands {
  margin-bottom: 1rem;
}

.brandContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  list-style-type: none;
}

.brandItem {
  flex: 1;
  max-width: 150px;
  min-width: 120px;
  margin: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
